<script setup lang="ts">
import { ref } from 'vue';
import { Separator } from '@/components/ui/separator';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import FormField from '@/components/FormField.vue';

import { useAuthStore } from '@/stores/auth';
import logoUrl from '@/assets/beck-logo.svg';
import { getApiErrorMessage } from '@/utils/api';
import { type AxiosError } from 'axios';
import * as z from 'zod';
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { Toaster } from '@/components/ui/sonner';
import { TooltipProvider } from '@/components/ui/tooltip';

const submitMessage = ref<string | null>(null);

const validationSchema = toTypedSchema(
  z
    .object({
      username: z.string({ required_error: 'Username is required' }),
      password: z.string({ required_error: 'Password is required' }),
    })
    .strict(),
);

const { handleSubmit } = useForm({ validationSchema });

async function submitRequest(values: { username: string; password: string }) {
  const auth = useAuthStore();
  const { username, password } = values;
  submitMessage.value = null;

  try {
    await auth.login(username, password);
  } catch (e) {
    const msg = getApiErrorMessage(e as AxiosError);
    console.error('Login Error: ', e, msg);
    submitMessage.value = msg ?? 'Unknown error occurred';
  }
}

const onSubmit = handleSubmit(submitRequest);
</script>

<template>
  <TooltipProvider>
    <div id="app-content-wrapper">
      <Toaster position="top-right" />
      <main class="container sm:max-w-30rem sm:mx-auto">
        <img class="mx-auto my-4 p-4 w-full max-w-[30rem]" :src="logoUrl" />
        <Card class="mx-auto w-full sm:max-w-[24rem]">
          <CardHeader>
            <CardTitle>Login</CardTitle>
          </CardHeader>
          <form @submit="onSubmit">
            <CardContent class="flex flex-col gap-2">
              <FormField
                name="username"
                label="Username"
                class="w-full max-w-sm"
                placeholder="Username"
                autocomplete="username" />
              <FormField
                name="password"
                label="Password"
                class="w-full max-w-sm"
                type="password"
                placeholder="Password"
                autocomplete="current-password" />
              <span
                id="login-error"
                :class="['text-red-500', { hidden: !submitMessage }]"
                >{{ submitMessage }}</span
              >
            </CardContent>
            <CardFooter>
              <Button type="submit" class="w-full">Log In</Button>
            </CardFooter>
          </form>
        </Card>
        <div class="fixed bottom-0 left-0 right-0 p-4">
          <div class="flex justify-center gap-3 h-5 items-center my-2">
            <a
              class="text-primary hover:underline"
              href="mailto:support@beckenterprises.com"
              >Support</a
            >
            <Separator orientation="vertical" />
            <a
              class="text-primary hover:underline"
              href="mailto:security@beckenterprises.com"
              >Security</a
            >
          </div>
          <div class="text-center">
            <a
              class="text-primary hover:underline"
              href="https://www.beckenterprises.com">
              Beck Enterprises of Woodbury, LLC.
            </a>
            &copy; {{ new Date().getFullYear() }} All Rights Reserved
          </div>
        </div>
      </main>
    </div>
  </TooltipProvider>
</template>

<style>
#app {
  isolation: isolate;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'content';
}

#app-content-wrapper {
  grid-area: content;
  overflow-y: auto;
  max-height: 100%;
  background-color: hsl(var(--background));
}

/* 576px is the primeflex small breakpoint */
@media (max-width: 576px) {
  /* On mobile, unstick the header to gain a bit more screen real estate back. */
  #app {
    overflow-y: auto;
  }

  #app-content-wrapper {
    overflow-y: unset;
  }
}
</style>

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Login from './Login.vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import '@/assets/index.css';

const pinia = createPinia();
const app = createApp(Login)
  .use(VueQueryPlugin, { enableDevtoolsV6Plugin: true })
  .use(pinia);

app.mount('#app');
